import React from "react";
import { Route, Switch } from "react-router-dom";

import Video from "./pages/Video";
import About from "./pages/About";
import Music from "./pages/Music";
import Gallery from "./pages/Gallery";
import Events from "./pages/Events";
import Shop from "./pages/Shop";
import Press from "./pages/Press";
import NyEp from "./pages/NyEp";

export default function Router() {
  return (
    <Switch>
      <Route exact path="/" component={Video} />
      <Route path="/about" component={About} />
      <Route path="/ny-ep" component={NyEp} />
      <Route path="/music" component={Music} />
      <Route path="/gallery" component={Gallery} />
      <Route path="/events" component={Events} />
      <Route path="/shop" component={Shop} />
      <Route path="/press" component={Press} />
    </Switch>
  );
}
