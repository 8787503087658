import React from "react";

import { Page, DownloadLink } from "../components";

export default function Press() {
  return (
    <Page title="Press">
      <DownloadLink href="https://spark.adobe.com/page/Kq5zaGNahpH6I/">
        View Press Kit in browser
      </DownloadLink>
      <br></br>
      <DownloadLink href="https://drive.google.com/file/d/1fO7NYmzJBUQzYfBQfT9XBf-hbZO1PRDu/view?usp=sharing">
        Download Press Kit
      </DownloadLink>
      <br></br>
      <DownloadLink href="https://drive.google.com/open?id=1Xw2Ui9aGtk_prhrOIQKpGpuE7I_EL2fI">
        Download Tech Rider
      </DownloadLink>
    </Page>
  );
}
