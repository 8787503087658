import React from "react";

import { Page } from "../components";

export default function Events() {
  return (
    <Page>
      <iframe
        src="https://calendar.google.com/calendar/embed?src=nti9rtoohicjn71uat1lv73rf8%40group.calendar.google.com&ctz=Europe%2FStockholm"
        style={{ border: 0 }}
        width="800"
        height="600"
        frameBorder="0"
        scrolling="no"
        title="Soul of Mother Earth calendar"
      />
    </Page>
  );
}
