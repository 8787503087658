import React from "react";
import g from "glamorous";

export const DownloadLink = ({ href, children }) => {
  return (
    <g.A
      download
      href={href}
      css={{
        color: "black",
        transition: "opacity .2s",
        textAlign: "center",
        "&:hover": {
          opacity: 0.5
        }
      }}
    >
      {children}
    </g.A>
  );
};
