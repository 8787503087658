import React from "react";
import Youtube from "react-youtube";
import g from "glamorous";
import FadeIn from "react-fade-in";

export default function Video() {
  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: 1,
      mute: 1,
      rel: 0,
    },
  };

  return (
    <g.Div
      css={{
        display: "flex",
        flex: 1,
        "> div": { display: "flex", flex: 1 },
        "> div > div": { display: "flex", flex: 1 },
      }}
    >
      <FadeIn>
        <g.Div
          css={{
            backgroundColor: "#1e1e1e",
            flexGrow: 1,
          }}
        >
          <Youtube videoId="E58sstJNuwU" opts={opts} />
        </g.Div>
      </FadeIn>
    </g.Div>
  );
}
