import React from "react";
import g from "glamorous";
import Youtube from "react-youtube";

import { Page } from "../components";

export default function About() {
  const opts = {
    height: "450px",
    width: "100%",
    playerVars: {
      autoplay: 1,
      mute: 1,
      rel: 0
    }
  };

  return (
    <Page title="About">
      <p>
        Soul of Mother Earth was formed in the summer of 2018 by Dvora Gala Blu
        (UK) and Anders “Andesh” Ek (SWE). They played their first gig at the
        Dream Spirit Festival in Innsbruck and has since spread out to the
        Netherlands, Austria and Germany where they recently made a big impact
        at the World Music Festival in Loshausen. The duo’s message, presented
        by yoga teacher, mystic and singer/songwriter Dvora Gala Blu, is clear:
        Search within yourself, unlock your potential through ancient techniques
        and see the world around you change.
        <br /> In a time when positivity around human longevity seems hard to
        find, Soul of Mother Earth delivers the keys to unlock the heart and
        soul of the listener, raise the vibration and inspire.
      </p>
      <p>
        Dvora’s message is backed up by Andesh, guitarist, loop artist and sound
        scape engineer with sounds and rythms designed to bring out the very
        essance of the soul, leading this dynamic duo to it’s completion. With
        the music tuned to the frequency of love, 528Hz, the truly unique,
        spiritual journey Soul of Mother Earth delivers is sure to leave no
        audience member unaffected.
      </p>
      <g.Div
        css={{
          backgroundColor: "#1e1e1e",
          flexGrow: 1
        }}
      >
        <Youtube videoId="WfumM_wcvLU" opts={opts} />
      </g.Div>
    </Page>
  );
}
