import React from "react";
import g from "glamorous";

import { Page, H1, H2 } from "../components";

export default function Shop() {
  return (
    <Page title="Shop">
      <g.Div css={{ display: "flex" }}>
        <g.Div css={{ width: "50%" }}>
          <H2>Awakening - Full Album - 2019</H2>
          <g.Img
            src="/assets/cd-cover.jpg"
            css={{ maxWidth: "50%", display: "block" }}
          />
          <p>
            Get a digital copy here:{" "}
            <a
              css={{ color: "#fff" }}
              target="_blank"
              href="https://www.amazon.de/Awakening-Soul-Mother-Earth/dp/B07Q4XY8HV/ref=sr_1_10?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&keywords=soul+of+mother+earth&qid=1558972639&s=gateway&sr=8-10"
            >
              here
            </a>
          </p>
          <p>
            <a href="http://paypal.me/soulofmotherearth/18EUR" target="_blank">
              Or order a physical copy (digipack)
            </a>{" "}
            *
          </p>
          <p>
            * Please add your delivery address in the "Add a note" field (you
            have to scroll up after clicking the link).
          </p>
        </g.Div>
        <g.Div css={{ width: "50%" }}>
          <H2>Guardian Angel - EP - 2019</H2>
          <g.Img
            src="/assets/ep-cover.jpg"
            css={{ maxWidth: "50%", display: "block" }}
          />
          <p>
            Get a digital copy here:{" "}
            <a
              css={{ color: "#fff" }}
              target="_blank"
              href="https://www.amazon.de/Guardian-Angel-Soul-Mother-Earth/dp/B07Y6VMMFC/ref=sr_1_1?keywords=Soul+Of+Mother+Earth&qid=1571247535&s=dmusic&search-type=ss&sr=1-1"
            >
              here
            </a>
          </p>
          <p>
            <a href="http://paypal.me/soulofmotherearth/12EUR" target="_blank">
              Or order a physical copy (jacket)
            </a>{" "}
            *
          </p>
          <p>
            * Please add your delivery address in the "Add a note" field (you
            have to scroll up after clicking the link).
          </p>
        </g.Div>
      </g.Div>
    </Page>
  );
}
