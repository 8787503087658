import React from "react";
import g from "glamorous";
import FadeIn from "react-fade-in";

import { H1 } from ".";

export const Page = ({ title, subtitle, children }) => {
  return (
    <g.Div
      css={{
        maxWidth: 1000,
        minWidth: 740,
        paddingLeft: 20,
        paddingRight: 20,
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: 20,
        paddingBottom: 20
      }}
    >
      <FadeIn>
        <H1>{title}</H1>
        {children}
      </FadeIn>
    </g.Div>
  );
};
