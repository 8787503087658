import React from "react";
import { NavLink } from "react-router-dom";

import "./Menu.css";

export const Menu = ({ openDialog }) => {
  return (
    <div className="Menu">
      <nav>
        <NavLink exact to="/" activeClassName="selected">
          Home
        </NavLink>
        <NavLink to="/about" activeClassName="selected">
          About
        </NavLink>
        <NavLink to="/music" activeClassName="selected">
          Music
        </NavLink>
        <NavLink to="/gallery" activeClassName="selected">
          Gallery
        </NavLink>
        <NavLink to="/events" activeClassName="selected">
          Events
        </NavLink>
        {/* <NavLink to="/ny-ep" activeClassName="selected">
          NY EP
        </NavLink> */}
        <NavLink to="/press" activeClassName="selected">
          Press
        </NavLink>
        <NavLink to="/shop" activeClassName="selected">
          Shop
        </NavLink>
        {/* <a
          href="#"
          onClick={() => openDialog()}
          className="Menu__new-album-link"
        >
          New album out!
        </a> */}
      </nav>
    </div>
  );
};
