import React from "react";
import GalleryLib from "react-photo-gallery";
import Lightbox from "react-images";

import { Page } from "../components";

export default class Gallery extends React.Component {
  state = { currentImage: 0, lightboxIsOpen: false };

  openLightbox = (event, obj) => {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true
    });
  };

  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false
    });
  };

  gotoPrevious = () => {
    this.setState({
      currentImage: this.state.currentImage - 1
    });
  };

  gotoNext = () => {
    this.setState({
      currentImage: this.state.currentImage + 1
    });
  };

  render() {
    return (
      <Page>
        <GalleryLib photos={PHOTO_SET} onClick={this.openLightbox} />
        <Lightbox
          images={PHOTO_SET}
          onClose={this.closeLightbox}
          onClickPrev={this.gotoPrevious}
          onClickNext={this.gotoNext}
          currentImage={this.state.currentImage}
          isOpen={this.state.lightboxIsOpen}
        />
      </Page>
    );
  }
}

const PHOTO_SET = [
  {
    src: "/assets/1.jpg",
    width: 4,
    height: 3
  },
  {
    src: "/assets/LIVEPIC4.jpg",
    width: 4,
    height: 2
  },
  {
    src: "/assets/Gallerypic2-min.jpg",
    width: 3,
    height: 2
  },
  {
    src: "/assets/2.jpg",
    width: 3,
    height: 2
  },
  {
    src: "/assets/LIVEPIC8.jpg",
    width: 4,
    height: 2
  },
  {
    src: "/assets/Gallerypic4-min.jpg",
    width: 3,
    height: 2
  },
  {
    src: "/assets/LIVEPIC9.jpg",
    width: 4,
    height: 2
  },
  {
    src: "/assets/Gallerypic5-min.jpg",
    width: 3,
    height: 2
  },
  {
    src: "/assets/Gallerypic7-min.jpg",
    width: 3,
    height: 2
  },
  {
    src: "/assets/3.jpg",
    width: 3,
    height: 2
  },
  {
    src: "/assets/4.jpg",
    width: 3,
    height: 2
  }
];
