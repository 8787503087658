import React from "react";
import g from "glamorous";
import { margin } from "polished";
import GoogleFontLoader from "react-google-font-loader";

import Router from "./Router";
import { Menu, Footer, NewRecordModal } from "./components";

class App extends React.Component {
  state = {
    modalIsOpen: false
  };

  componentDidMount() {
    // this.setState({ modalIsOpen: true });
  }

  render() {
    return (
      <g.Div className="App" css={{ display: "flex", flexDirection: "column" }}>
        <GoogleFontLoader fonts={[{ font: "Amatic SC", weights: [400] }]} />
        <Menu openDialog={() => this.setState({ modalIsOpen: true })} />
        <g.Div
          className="container"
          css={{
            flex: "1 0 auto",
            display: "flex",
            width: "100%",
            ...margin("0", "auto")
          }}
        >
          <Router></Router>
        </g.Div>
        <Footer />
        <NewRecordModal
          open={this.state.modalIsOpen}
          handleCloseModal={() => this.setState({ modalIsOpen: false })}
        />
      </g.Div>
    );
  }
}

export default App;
