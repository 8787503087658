import React from "react";
import Modal from "react-modal";
import g from "glamorous";

import { H1 } from ".";

export const NewRecordModal = ({ open, handleCloseModal }) => {
  return (
    <Modal
      isOpen={open}
      onAfterOpen={() => {}}
      onRequestClose={handleCloseModal}
      style={{
        content: {
          backgroundColor: "#4b372e",
          opacity: 0.9
        }
      }}
      contentLabel="New album"
    >
      <g.Div
        css={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <H1 css={{ color: "#fff" }}>"Awakening" out now</H1>
        <g.Img
          src="/assets/cd-cover.jpg"
          css={{ maxWidth: "50%", display: "block" }}
        />
        <p>
          Get a digital copy here:{" "}
          <a
            css={{ color: "#fff" }}
            target="_blank"
            href="https://www.amazon.de/Awakening-Soul-Mother-Earth/dp/B07Q4XY8HV/ref=sr_1_10?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&keywords=soul+of+mother+earth&qid=1558972639&s=gateway&sr=8-10"
          >
            here
          </a>
        </p>
        <p>
          <a href="http://paypal.me/soulofmotherearth/18EUR" target="_blank">
            Or order a physical copy (digipack)
          </a>{" "}
          *
        </p>
        <p>
          * Please add your delivery address in the "Add a note" field (you have
          to scroll up after clicking the link).
        </p>

        <g.Button
          css={{ position: "absolute", top: 10, right: 10 }}
          onClick={handleCloseModal}
        >
          X
        </g.Button>
      </g.Div>
    </Modal>
  );
};
