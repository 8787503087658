import React from "react";
import Youtube from "react-youtube";

import { Page } from "../components";

export default function Music() {
  return (
    <Page title="Music">
      <div className="music-inner">
        <div className="youtube-container">
          <Youtube videoId="E58sstJNuwU" />
          <Youtube videoId="Fill60F5EL0" />
          <Youtube videoId="htzWF4QAc8k" />
        </div>
        <div className="spotify-container">
          <iframe
            src="https://open.spotify.com/embed/artist/05RlpJgMSr2XgxtJQQdEJK"
            width="300"
            height="380"
            frameborder="0"
            allowtransparency="true"
            allow="encrypted-media"
          />
          <iframe
            src="https://open.spotify.com/embed/album/4RtP3h3xFk5PjcgevhuGpf?si=cmsnpraHTZatbgebwjLe6Q"
            width="300"
            height="380"
            frameborder="0"
            allowtransparency="true"
            allow="encrypted-media"
          />
        </div>
      </div>
    </Page>
  );
}
