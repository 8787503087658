import React from "react";
import g from "glamorous";
import { margin, padding } from "polished";

import "./Footer.css";

const linkStyle = {
  color: "white",
  textDecoration: "none"
};

export const Footer = () => (
  <g.Div className="Footer">
    <g.Div
      css={{
        maxWidth: 740,
        ...margin(0, "auto"),
        ...padding(0, 20),
        display: "flex",
        justifyContent: "space-between"
      }}
    >
      <g.A css={linkStyle} href="mailto:soulofmotherearth@gmail.com">
        soulofmotherearth@gmail.com
      </g.A>
      <g.Span css={{ color: "white", opacity: 0.7 }}>- | -</g.Span>
      <g.A css={linkStyle} href="tel:+49 179 7435970">
        +49 179 7435970
      </g.A>
    </g.Div>
  </g.Div>
);
